<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.DATE") }}</dt>
          <dd>
            {{ $formatDate(resellerPayment.date) }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
        >
          <dt>{{ $t("COMMON.INVOICE") }}</dt>
          <dd>
            <object-link :object="resellerPayment.invoice" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="resellerPayment.reseller" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <reseller-payment-status-badge :resellerPayment="resellerPayment" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(resellerPayment.amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("RESELLER_PAYMENTS.SOURCE") }}</dt>
          <dd>
            {{
              $t(
                `RESELLER_PAYMENTS.RESELLER_PAYMENT_SOURCE_${resellerPayment.source}`
              )
            }}
          </dd>
        </dl>

        <dl class="row" v-if="resellerPayment.excerpt">
          <dt>
            {{ $t("COMMON.EXCERPT") }}
          </dt>
          <dd>
            <div v-html="resellerPayment.excerpt"></div>
          </dd>
        </dl>

        <dl class="row" v-if="resellerPayment.transaction_id">
          <dt>
            {{ $t("RESELLER_PAYMENTS.TRANSACTION_ID") }}
          </dt>
          <dd>
            {{ resellerPayment.transaction_id }}
          </dd>
        </dl>

        <dl class="row" v-if="resellerPayment.transaction_data">
          <dt>
            {{ $t("RESELLER_PAYMENTS.TRANSACTION_DATA") }}
          </dt>
          <dd>
            {{ resellerPayment.transaction_data }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="resellerPayment.created_at">
            {{ $formatDate(resellerPayment.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="resellerPayment.updated_at">
            {{ $formatDate(resellerPayment.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import ResellerPaymentStatusBadge from "./ResellerPaymentStatusBadge.vue";
export default {
  name: "reseller-payment-view-global",

  components: { ResellerPaymentStatusBadge },

  props: ["resellerPayment"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerPayment(resellerPayment) {},
  },
};
</script>
