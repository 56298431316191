<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!resellerPayment">
      <span class="loader"></span>
    </span>
    <div v-if="resellerPayment" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("COMMON.PAYMENT_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{
                  $t("RESELLER_PAYMENTS.RESELLER_PAYMENTS_LIST")
                }}</span>
              </li>
              <li>
                <span>{{ resellerPayment.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button class="delete" @click="deleteResellerPayment">
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button class="edit" @click="editResellerPayment">
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav kw-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <reseller-payment-view-global :resellerPayment="resellerPayment" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <reseller-payment-view-logs :resellerPayment="resellerPayment" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { PAYMENT_STATUS_DRAFT } from "@/constants/payments";
import { Tabs, TabPane } from "@/components";
import ResellerPaymentViewGlobal from "../partials/ResellerPaymentViewGlobal.vue";
import ResellerPaymentViewLogs from "../partials/ResellerPaymentViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ResellerPaymentViewGlobal,
    ResellerPaymentViewLogs,
  },

  mixins: [],

  props: {
    resellerPaymentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      resellerPayment: null,
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "resellerPayments/get",
          this.resellerPaymentId
        );
        this.resellerPayment =
          this.$store.getters["resellerPayments/resellerPayment"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewInvoice() {
      this.$router.push(this.$objectViewRoute(this.resellerPayment.invoice));
    },

    editResellerPayment() {
      this.$emit("onEditResellerPayment", this.resellerPayment);
    },

    deleteResellerPayment() {
      this.$emit("onDeleteResellerPayment", this.resellerPayment);
    },
  },
};
</script>
